import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from '../components/Homepage.js';
import { OpenSource, AnalyzesData, AutomateModularManagement } from '@carbon/pictograms-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const SquareCard = makeShortcode("SquareCard");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Row mdxType="Row">
  <Column colLg={9} mdxType="Column">
        <h2>{`Managed services approach designing, implementing, and integrating AI solutions within your organization, adding value to your business within budget. We bring 20+ years of industry experience in applied data science projects.`}</h2>
  </Column>
    </Row>
    <hr />
    <Row mdxType="Row">
  <Column colSm={3} colMd={5} colLg={3} mdxType="Column">
        <h3><strong parentName="h3">{`Quality`}</strong>{` is in our DNA`}</h3>
  </Column>
  <Column colSm={6} colMd={9} colLg={9} mdxType="Column">
        <h2>{`Working along with TekDatum means you have the best talent rounding out your existing team, supporting growth with cutting-edge solutions, and guaranteeing quality in every step.`}</h2>
  </Column>
    </Row>
    <Row mdxType="Row">
  
  <Column colLg={9} offsetLg={3} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRl4AAABXRUJQVlA4IFIAAACQAwCdASoUAAsAPtFWo0uoJKMhsAgBABoJaQAAW61Kp7Wf92AAAP72ildCruUl84KpqTeL6ZxLwAivtc9L10NLOol/vwW3Ylgl7ui7nie4gAAA')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e1b86742d531b0d1d49cf1e5e9ee2db7/b5e35/paint_large.webp 288w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/0bf43/paint_large.webp 576w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/73abe/paint_large.webp 1152w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/b9259/paint_large.webp 1728w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/37df7/paint_large.webp 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e1b86742d531b0d1d49cf1e5e9ee2db7/b5e35/paint_large.webp 288w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/0bf43/paint_large.webp 576w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/73abe/paint_large.webp 1152w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/b9259/paint_large.webp 1728w", "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/37df7/paint_large.webp 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e1b86742d531b0d1d49cf1e5e9ee2db7/73abe/paint_large.webp",
              "alt": "Data Science",
              "title": "Data Science",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
  </Column>
    </Row>
    <Row className="index-row" mdxType="Row">
  <Column colMd={4} colLg={3} offsetLg={3} mdxType="Column">
  <Row className="square-card-group" mdxType="Row">
  <SquareCard className="" title="Open Source Focus" bodyText="Whenever possible, we would suggest using existing open-source frameworks and technologies, having product quality and customer experience as our priorities." actionIcon="" href="javascript:;" mdxType="SquareCard">
    <OpenSource aria-label="OpenSource" className="squaredCard-home" mdxType="OpenSource" />
  </SquareCard>
  </Row>
  </Column>
  <Column colMd={4} colLg={3} mdxType="Column">
    <Row className="square-card-group" mdxType="Row">
      <SquareCard title="Data Science techniques + QA practices" bodyText="We are a unique, data-driven consulting firm. We use powerful DS techniques and QA practices to help our clients solve exciting business problems and optimize their business or products." actionIcon="" href="javascript:;" mdxType="SquareCard">
      <AnalyzesData aria-label="AnalyzesData" className="squaredCard-home" mdxType="AnalyzesData" />
    </SquareCard> 
    </Row>
  </Column>
  <Column colMd={4} colLg={3} mdxType="Column">
    <Row className="square-card-group" mdxType="Row">
      <SquareCard title="Managed Services Approach" bodyText="Managing data science talent requires a unique combination of management skills and data science knowledge. We do this on your behalf so you can focus on your business." actionIcon="" href="javascript:;" mdxType="SquareCard">
      <AutomateModularManagement aria-label="ManagedServices" className="squaredCard-home" mdxType="AutomateModularManagement" />
     </SquareCard>
    </Row>
  </Column>
    </Row>
    <hr />
    <Row className="index-row" mdxType="Row">
  <Column colMd={12} colLg={3} mdxType="Column">
        <h3>{`Take a Deeper Look at Our Work`}</h3>
  </Column>
  <Column colMd={3} colLg={3} mdxType="Column">
  <ArticleCard author="Motus, LLC" title="Enhanced testing with cutting-edge automation tools for Reimbursements systems" href="/work" color="dark" actionIcon="arrowRight" className="articleCard-homepage" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "600px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC1ElEQVQ4y42US09TQRTH71Z3bkxINLo1Lt1oXBi/gCviZzBx4xJ3xmgiEQMJqYI8IiwUSqFCy6OlD6gSK9AEoQnYUry998zMvX0TY2J08TczfUIv4OJk7tw553f+c87JaIwxHDeiqjFGje/WfXNtN80ZRirIqIEsRjUIOcBPAdYd67CyINicsJdlMB1UO0EdgTK4JAieXYFby0Vc9JWwo1eVpo1m0qaAU4B1ZcM7FjRPGdqHCs59LOObzrGlcyymBFb3uYI3lZ4CFIywZzB0+ErQJiu4t1LAyj6HbjLMfRd4spnDo685ZEwGftaVpbpDQRhLWtDcFVyZL+HAZKgIUsH7JsPDeB43gkUEUgJFXi3PicAsMfy0CH1bNrSJCu6ECyhwUlB5ZjPC/VgeV+dLmN4Vqs7GWQql0+yeUPU7/7GM5bTAnxzhb85U9evwl3Bhroxwmqtk5lk1rM/d7VBRNeSyv4THGzl0redwyV9S/+TZfzWlPjJ5Tlg/4Li2WFRX194fVm2igutLRWz+4MrHdBqb6vRTTSGBkawLqQA5c88SNjpjeXSu5vE8YanGyDOj5ns8XjsCq2XhnFezE+GXTaqbhzkLvwsWOFHjrFkqOnpl+UMIAcM0YJomstmsOiwIhoxBsC0LG4kEPn+Jw7Jt5SOTSh9d19sVSlgqlcLQ8BAG3w6i3+WCf3oSQzsWutZt7JKN/jcD6O5+AZ/Ph2QyCc+0R/nPeGdUggZQbgqFAoLBILxeL9xTU5hfWMC70WHcnNMR2bfwdLsCl3cJs55JjI2PIx6Pw/XahYHBAYyMjjRu1ADm83nEYjFEo1GEwiF8WlvD4uwMXsYPcDdSxIONCkLRFUQiYQQCAfS86oHf74fb7UZvXy8ymYwqQVtTJLzVcpywrTMQYxBkwqz5ptNpBZCrVFeHHWmK0+sruynfQ/VUtXRU1ry+tsLaFDpb8xU/yVrH5x9xMHvfmWRqfgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/12c82fb9ed146d8912276f8203c3d53f/b5e35/motus_600_gray.webp 288w", "/static/12c82fb9ed146d8912276f8203c3d53f/0bf43/motus_600_gray.webp 576w", "/static/12c82fb9ed146d8912276f8203c3d53f/504ac/motus_600_gray.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/12c82fb9ed146d8912276f8203c3d53f/0b458/motus_600_gray.png 288w", "/static/12c82fb9ed146d8912276f8203c3d53f/91cfa/motus_600_gray.png 576w", "/static/12c82fb9ed146d8912276f8203c3d53f/8dd8b/motus_600_gray.png 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/12c82fb9ed146d8912276f8203c3d53f/8dd8b/motus_600_gray.png",
                "alt": "Motus, LLC",
                "title": "Motus, LLC",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>
  </Column>
  <Column colMd={3} colLg={3} mdxType="Column">
    <ArticleCard author="Edify Software Consulting" title="Established high-quality standards for testing Educational Software" href="/work" color="dark" actionIcon="arrowRight" className="articleCard-homepage" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "600px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/webp;base64,UklGRqgAAABXRUJQVlA4IJwAAABwBACdASoUABQAPtFcpk6oJKMiKAqpABoJQBfoxAG8eAMvD/J2dEcduaytQAD+ufCw6GGCBU+YDZLHh748Za1ZYbCDSywxaLoZshivDZ0MHoedIjuepkjRMtXagZOV70COQKepHrHaRIpT+qpxWJQT/9sbBPVHY6+BUChT/rK19Fj94BjqjX9icFDf0oW1hqYXS+9mLa+Ka5P8+AA=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e5235a62879eabba0b17c7df2cdeb280/b5e35/edify-logo-dark.webp 288w", "/static/e5235a62879eabba0b17c7df2cdeb280/0bf43/edify-logo-dark.webp 576w", "/static/e5235a62879eabba0b17c7df2cdeb280/504ac/edify-logo-dark.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e5235a62879eabba0b17c7df2cdeb280/b5e35/edify-logo-dark.webp 288w", "/static/e5235a62879eabba0b17c7df2cdeb280/0bf43/edify-logo-dark.webp 576w", "/static/e5235a62879eabba0b17c7df2cdeb280/504ac/edify-logo-dark.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/e5235a62879eabba0b17c7df2cdeb280/504ac/edify-logo-dark.webp",
                "alt": "Edify",
                "title": "Edify",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>  
  </Column>
  <Column colMd={3} colLg={3} mdxType="Column">
        {/* Aquestive Data commented
            <ArticleCard
              title="State of the art drug design data science predictions"
              author="Aquestive"
              href="/work"
              color="dark"
              actionIcon="arrowRight"
              className="articleCard-homepage">
         ![Aquestive](/components/images/Customers/aquestive_600_gray.webp)
           </ArticleCard> 
         */}
 <ArticleCard title="Design and development data science projects" author="Triunity, INC." href="/work" color="dark" actionIcon="arrowRight" className="articleCard-homepage" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "600px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/webp;base64,UklGRloAAABXRUJQVlA4IE4AAADQAwCdASoUABQAPtFapUuoJSOhsAgBABoJZwDLpCHfn2302SisWAAA/vYBb43QrS/jTUFV9BIN2HXBMnW26t0kfVQjZn5TIrKJfkPyAAA=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c1c0b3510856095e3fdcd1bc2e1a7636/b5e35/triunity_logo_600.webp 288w", "/static/c1c0b3510856095e3fdcd1bc2e1a7636/0bf43/triunity_logo_600.webp 576w", "/static/c1c0b3510856095e3fdcd1bc2e1a7636/504ac/triunity_logo_600.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c1c0b3510856095e3fdcd1bc2e1a7636/b5e35/triunity_logo_600.webp 288w", "/static/c1c0b3510856095e3fdcd1bc2e1a7636/0bf43/triunity_logo_600.webp 576w", "/static/c1c0b3510856095e3fdcd1bc2e1a7636/504ac/triunity_logo_600.webp 600w"],
                "sizes": "(max-width: 600px) 100vw, 600px",
                "type": "image/webp"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c1c0b3510856095e3fdcd1bc2e1a7636/504ac/triunity_logo_600.webp",
                "alt": "Triunity Inc.",
                "title": "Triunity Inc.",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>  
  </Column>
    </Row>
    <hr />
    <Row className="index-row" mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
        <h3>{`Our Strengths`}</h3>
  </Column>
  <Column colMd={3} colLg={3} noGutterLgLeft mdxType="Column">
  <ArticleCard title="Quality Mindset" color="dark" actionIcon="" href="javascript:;" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "480px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/webp;base64,UklGRoYAAABXRUJQVlA4IHoAAAAQBACdASoUABQAPtFco02oJSMiKA1RABoJaQAD46kJ1x3Ud2GhiVQMgAD+94DNWdJA/WJ9JICWlovAHnznbUBUcrsd3wv8JZIbOthEmXs6z7EBuA+pa6Bk+TZ/B8T9nh+QOZ+dBxYCsvQ+f3kQK2BJx70ETE3Ad8AAAA==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c899c8da8735f08e116780da8ecf68ff/b5e35/quiality_mindset_1.webp 288w", "/static/c899c8da8735f08e116780da8ecf68ff/21851/quiality_mindset_1.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c899c8da8735f08e116780da8ecf68ff/b5e35/quiality_mindset_1.webp 288w", "/static/c899c8da8735f08e116780da8ecf68ff/21851/quiality_mindset_1.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c899c8da8735f08e116780da8ecf68ff/21851/quiality_mindset_1.webp",
                "alt": "Quality mindsets team",
                "title": "Quality mindsets team",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>
  </Column>
  <Column colMd={3} colLg={3} noGutterLgLeft mdxType="Column">
    <ArticleCard title="Business-driven Solutions" color="dark" actionIcon="" href="javascript:;" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "480px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/webp;base64,UklGRowAAABXRUJQVlA4IIAAAACQBACdASoUABQAPtFgqU+oJSOiKAgBABoJaQAAMWYUa0oonjOhWMLSPMAqAAAA/uehxhveytTFSH6DagIxEX2z3cOgWJUujAVL8U58zDpGQNiP5LCXLkBPZLzaNY4Xx96/vfFiOGLBFoVJxNlFaEiZ00peT0CEcTewgnA80eAAAA==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/90514481103632989489b916e2680960/b5e35/businnes-driven.webp 288w", "/static/90514481103632989489b916e2680960/21851/businnes-driven.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/90514481103632989489b916e2680960/b5e35/businnes-driven.webp 288w", "/static/90514481103632989489b916e2680960/21851/businnes-driven.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/90514481103632989489b916e2680960/21851/businnes-driven.webp",
                "alt": "Business-driven Solutions",
                "title": "Business-driven Solutions",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>  
  </Column>
  <Column colMd={3} colLg={3} noGutterLgLeft mdxType="Column">
  <ArticleCard title="Data Science world class experts" color="dark" actionIcon="" href="javascript:;" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "480px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/webp;base64,UklGRnIAAABXRUJQVlA4IGYAAAAwBACdASoUABQAPtFcqE4oJSQiKAqpABoJZwDM0A+392+s5lg4gVhEeQAA8/J6EA/k5BAM8wU8WBOy/ZGHmXMQ5cHHC02S0wH1MfDuwfc0ag6DawgY0G1C6H1oChhHef5xw+u2AAA=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/9d911b27e6ffcb1ca821458318d8f7a6/b5e35/data_science_2.webp 288w", "/static/9d911b27e6ffcb1ca821458318d8f7a6/21851/data_science_2.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/9d911b27e6ffcb1ca821458318d8f7a6/b5e35/data_science_2.webp 288w", "/static/9d911b27e6ffcb1ca821458318d8f7a6/21851/data_science_2.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/9d911b27e6ffcb1ca821458318d8f7a6/21851/data_science_2.webp",
                "alt": "Data Science passionate experts",
                "title": "Data Science passionate experts",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
  </ArticleCard>  
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      